export enum LogisticOverviewType {
  MyRequests = "myrequests",
  MyOffers = "myoffers",
  Done = "done",
  Past = "past",
}
export function isLogisticOverviewType(
  value: string
): value is LogisticOverviewType {
  return Object.values(LogisticOverviewType).includes(
    value as LogisticOverviewType
  );
}

export const SEARCH_PARAM_USER_TRANSPORT_OFFER_ID = "usertransportofferid";
